import { useIntl } from 'react-intl';
import * as registrationDispatch from 'redux/ducks/registration';
import { getSelectedPointPath, RegistrationActions } from 'redux/ducks/registration';
import StepInfo from './StepInfo';
import * as React from 'react';
import { connect } from 'react-redux';
import { Slide, Grid, Stepper, Step, StepButton } from '@material-ui/core';
import classNames from 'classnames';
import registrationPointPlaceholder from 'static/img/product_placeholder.png';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import TextAreaInput from 'components/TextAreaInput';
import { makeStyles } from '@material-ui/core/styles';
import MultipleRegistrationTable from 'pages/Registration/Register/RegisterMultiple/MultipleRegistrationTable';
import SelectReason from './SelectReason/index';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getActiveReasons } from 'redux/ducks/reasons/selectors';
import { Reason } from 'redux/ducks/reasons';
import OptionalRegister from 'pages/Registration/Register/OptionalRegister';
import { useState } from 'react';
import { RegistrationPoint } from 'redux/ducks/data/registrationPoints';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export interface IComponentProps extends StateProps, DispatchProps {}

const useStyles = makeStyles((theme) => ({
  comment: {
    marginTop: theme.spacing(2)
  },
  commentLabel: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    color: theme.palette.common.black
  },
  reasonGridError: {
    boxSizing: 'border-box',
    border: '2px solid #f44236',
    padding: '0px 10px 10px 10px'
  },
  reasonGrid: {
    padding: '0px 10px 10px 10px'
  },
  stepperWrapper: {
    paddingTop: '10px',
    paddingBottom: '10px',
    marginLeft: '-15px'
  },
  stepper: {
    overflowX: 'auto',
    overflowY: 'hidden',
    WebkitOverflowScrolling: 'touch',
    padding: 0
  }
}));

const Overview: React.FunctionComponent<IComponentProps> = (props) => {
  const classes = useStyles(props);
  const intl = useIntl();
  const {
    enableRegistrationComments,
    enableRegistrationReason,
    comment,
    setComment,
    nodesHistory,
    updateStepper,
    setReason,
    selectedReason,
    step,
    reasons,
    isMultiView,
    invalidFields,
    allNodes,
    allowSkipLastRegistrationPoint,
    registrationPoint,
    selectOptionalRegistrationPoint
  } = props;

  const [selectedPoint, setSelectedPoint] = useState<RegistrationPoint | undefined>(undefined);

  const onCommentChange = (value: string) => {
    setComment(value);
  };

  const StepConnector = () => <ChevronRightIcon />;

  const reasonError = invalidFields.some(({ field }) => field === 'reason');

  const children = allNodes.filter(
    (node) => node.parentId === registrationPoint.id && node.active && !node.deletedAt
  );
  const showOptionalSelectRegistrationPoint = allowSkipLastRegistrationPoint && children.length > 0;

  const selectWrapperClass = classNames({
    [classes.reasonGridError]: enableRegistrationReason && reasonError,
    [classes.reasonGrid]: !reasonError && selectedReason
  });

  const handleUpdateStep = (index: number) => {
    updateStepper(index);
  };

  const handleSelectOptionalPoint = (point: RegistrationPoint) => {
    setSelectedPoint(point);
    selectOptionalRegistrationPoint(point);
  };

  return (
    <Grid container spacing={4} justify='center' className='overview'>
      <Grid item xs={12} className={classes.stepperWrapper}>
        <Stepper
          activeStep={step}
          nonLinear
          connector={<StepConnector />}
          className={classes.stepper}
        >
          {nodesHistory.map((point, index: number) => {
            return (
              <Slide in={true} direction={'right'} timeout={200} key={`${point.id}`}>
                <Step>
                  <StepButton onClick={() => handleUpdateStep(index)} completed={true}>
                    {point.name}
                  </StepButton>
                </Step>
              </Slide>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item xs={12} className={selectWrapperClass}>
        {showOptionalSelectRegistrationPoint || enableRegistrationReason ? (
          <>
            {showOptionalSelectRegistrationPoint && (
              <Grid item xs={12}>
                <OptionalRegister
                  onSelect={(point) => handleSelectOptionalPoint(point)}
                  points={children}
                  selected={selectedPoint}
                />
              </Grid>
            )}
            {enableRegistrationReason && (
              <SelectReason
                onSelect={setReason}
                reasons={reasons}
                error={reasonError}
                selected={selectedReason}
              />
            )}
          </>
        ) : isMultiView === true ? (
          <Grid item xs={12}>
            <MultipleRegistrationTable />
          </Grid>
        ) : (
          nodesHistory.map((point, index) => {
            return (
              <Slide in={true} timeout={200} key={point.id}>
                <StepInfo
                  step={index}
                  numOfSteps={nodesHistory.length - 1}
                  className={classNames('step-info')}
                  completed={true}
                  onClick={() => handleUpdateStep(index)}
                  image={point.image ? point.image : registrationPointPlaceholder}
                  description={intl.formatMessage({ id: point.label })}
                  title={
                    point.name
                      ? point.name
                      : intl.formatMessage({ id: 'registration.error.no_product' })
                  }
                  ingredients={point.ingredients ?? []}
                />
              </Slide>
            );
          })
        )}
      </Grid>
      {enableRegistrationComments && (
        <Grid item xs={12}>
          <TextAreaInput
            className={classes.comment}
            name='comments'
            label={intl.formatMessage({ id: 'base.comments' })}
            InputLabelProps={{ shrink: true, className: classes.commentLabel }}
            fullWidth
            multiline
            rows={5}
            rowsMax={50}
            value={comment}
            placeholder={intl.formatMessage({ id: 'base.comments.placeholder' })}
            onChange={onCommentChange}
            maxLength={255}
          />
        </Grid>
      )}
    </Grid>
  );
};
const mapStateToProps = (state: RootState) => {
  const { step, date, comment, reasonId, invalidFields } = state.registration;
  const { enableRegistrationComments, enableRegistrationReason, allowSkipLastRegistrationPoint } =
    state.settings;
  const reasons = getActiveReasons(state);
  const nodesHistory = getSelectedPointPath(state);
  const allNodes = state.data.registrationPoints.allNodes;
  const registrationPoint = state.registration.currentNodes[0];

  return {
    step,
    nodesHistory,
    date,
    comment,
    enableRegistrationComments,
    isMultiView: state.registration.currentNodes.length > 1,
    selectedReason: reasonId ? reasons.find((r) => r.id === reasonId) : undefined,
    enableRegistrationReason,
    reasons,
    invalidFields,
    allNodes,
    allowSkipLastRegistrationPoint,
    registrationPoint
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, RegistrationActions>) => ({
  updateStepper: (index: number) => {
    dispatch(registrationDispatch.updateStepper(index));
  },
  setDate: (date: Date) => {
    dispatch(registrationDispatch.setDate(date));
  },
  setComment: (comment: string) => dispatch(registrationDispatch.setComment(comment)),
  setReason: (reason: Reason) => dispatch(registrationDispatch.setReason(reason.id)),
  selectOptionalRegistrationPoint: (point: RegistrationPoint) =>
    dispatch(registrationDispatch.selectOptionalRegistrationPoint(point))
});
export default connect<StateProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(Overview);
