import { createSelectorCreator, defaultMemoize } from 'reselect';
import { getRegistrations } from 'redux/ducks/data/registrations/selectors';
import { Registration } from 'redux/ducks/data/registrations';
import isEqual from 'lodash/isEqual';
import { RootState } from 'redux/rootReducer';
import { CurrentRegistration } from 'redux/ducks/registration/types';
import { RegistrationPoint } from 'redux/ducks/data/registrationPoints';
import moment from 'moment';
import { API_DATE_FORMAT } from 'utils/datetime';

// create a custom "selector creator" that uses lodash.isEqual instead of the default equality check "==="
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getSelectedChildPoints = createDeepEqualSelector(
  (state: RootState) => state.registration.selectionParentPath,
  (state: RootState) => state.data.registrationPoints.allNodes,
  (path, nodes) => {
    if (path.length === 0) {
      return nodes.filter((node) => !node.parentId && node.active && !node.deletedAt);
    }

    return path
      .slice(-1)
      .flatMap((id) =>
        nodes.filter((node) => node.parentId === id && node.active && !node.deletedAt)
      );
  }
);

export const getSelectedPointPath = createDeepEqualSelector(
  (state: RootState) => state.registration.selectionParentPath,
  (state: RootState) => state.data.registrationPoints.registrationPointsMap,
  (path, nodeMap) => {
    if (path.length === 0) {
      return [];
    }

    return path.map((id) => nodeMap.get(id));
  }
);

export const getMultipleRegistration = createDeepEqualSelector(
  (state: RootState) => state.registration.currentNodes,
  (state: RootState) => state.registration.currentRegistrations,
  (currentNodes, currentRegistrations) => {
    const finalArray = currentNodes.map((node) => {
      const formatedRoot = currentRegistrationDataFormat(node);

      const findEditedCurrentRegistration = currentRegistrations.find(
        (registration) => formatedRoot.id === registration.id
      );

      return {
        ...formatedRoot,
        ...findEditedCurrentRegistration
      };
    });

    return selectFirstAvailableRow(finalArray);
  }
);

export const currentRegistrationDataFormat = (
  root: RegistrationPoint,
  registration?: Registration,
  status?: CurrentRegistration['status']
): CurrentRegistration => {
  const { id, name, image } = root;
  return {
    status: status,
    unit: 'g',
    date: registration?.date,
    id,
    name,
    image,
    amount: registration?.amount,
    registrationId: registration?.id,
    comment: registration?.comment,
    reasonId: registration?.reasonId,
    offline: registration?.offline
  };
};

// prevents adding a duplicate object
// updates the current registrations array with the new registration
// todo: unnecessary, just store these in a map datastructure instead
export function updateCurrentRegistrationArray(
  currentRegistrations: CurrentRegistration[],
  newRegistration: CurrentRegistration
): CurrentRegistration[] {
  const isDuplicate = currentRegistrations.some(
    (registration) => registration.id === newRegistration.id
  );

  if (isDuplicate) {
    return currentRegistrations.map((registration) =>
      registration.id === newRegistration.id
        ? { ...registration, ...newRegistration }
        : registration
    );
  } else {
    return [...currentRegistrations, newRegistration];
  }
}

const selectFirstAvailableRow = (registrations: CurrentRegistration[]): CurrentRegistration[] => {
  let selected = false; // flag for knowing if one registration was selected already
  return registrations.map((regPoint) => {
    const editable = regPoint.status !== undefined; // change to "true" if you want pencil icon displayed
    if (regPoint.status === undefined && selected === false) {
      selected = true;
      return {
        ...regPoint,
        selected: true,
        editable: editable
      };
    } else {
      return {
        ...regPoint,
        selected: false,
        editable: editable
      };
    }
  });
};

// todo: add separate prop for previousRegistration
export const selectLastRegistration = createDeepEqualSelector(
  (state: RootState) => state.registration.currentRegistrations,
  (currentRegistrations) => {
    if (currentRegistrations) {
      const firstRegistration = currentRegistrations[0];
      const areRegistrationDatesEqual = currentRegistrations.every(
        (registration) => registration.date === firstRegistration.date
      );

      if (firstRegistration) {
        const totalWeightCalc = currentRegistrations.reduce((total = 0, node) => {
          return node.status === 'registered' ? total + node.amount : total;
        }, 0);

        return {
          ...firstRegistration,
          amount: totalWeightCalc,
          name: firstRegistration.name,
          date: areRegistrationDatesEqual
            ? moment(firstRegistration.date, API_DATE_FORMAT).format('L')
            : 'Multiple dates'
        };
      } else return undefined;
    } else return undefined;
  }
);

export const getFreePlanRegistrationHistory = createDeepEqualSelector(
  (state: RootState) => getRegistrations(state),
  (registrations) => {
    const byDate = registrations.reduce((date, curr) => {
      const formattedDate = moment(curr.date).format(API_DATE_FORMAT);
      const currWithName = {
        ...curr,
        name: curr.registrationPoint ? curr.registrationPoint.name : null
      };

      return {
        ...date,
        [formattedDate]: date[formattedDate]
          ? [...date[formattedDate], currWithName]
          : [currWithName]
      };
    }, {} as { [key: string]: Registration[] });

    const now = moment.utc();
    const pastWeek = moment.utc().subtract(7, 'day');

    for (const day = pastWeek; day.isSameOrBefore(now, 'day'); day.add(1, 'day')) {
      const formattedDate = day.format(API_DATE_FORMAT);
      if (!byDate[formattedDate]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        byDate[formattedDate] = [{ id: formattedDate, date: formattedDate }];
      }
    }

    return Object.values(byDate).flatMap((identity) => identity);
  }
);
