import { RegistrationPoint } from 'redux/ducks/data/registrationPoints/types';
import { Registration } from 'redux/ducks/data/registrations';

export type StepShape = 0 | 1 | 2;

export type ConnectionType = 'USB' | 'BLE';
export type ScaleStatus = {
  isConnecting?: boolean;
  isConnected: boolean;
  type?: ConnectionType;
};
export type RegistrationStatus = 'skipped' | 'registered';
export type RegistrationAvoidable = 'yes' | 'no' | 'partly';

export type RegistrationFieldValidation = {
  field: 'date' | 'weight' | 'registrationPoint' | 'reason';
  reason: 'missing' | 'invalid';
};

export interface RegistrationState {
  weight: number;
  date: Date;
  comment?: string;
  step: StepShape;
  loading: boolean;
  scaleStatus: ScaleStatus;
  pageNumber: number;
  updatedAt: Date;
  /* Selected registration points, if length > 1, we have multi register */
  currentNodes: RegistrationPoint[];
  reasonId?: string;
  currentRegistrations: CurrentRegistration[];
  // only relevant in multi register, but unnecessary flag (derivable)
  allRegistrationPointsAreHandled: boolean;
  avoidable: RegistrationAvoidable;
  funFact: FunFact;
  invalidFields: RegistrationFieldValidation[];
  /* Parent ids of the selected registration points (current nodes), in order */
  selectionParentPath?: string[];
  optionalSelected?: RegistrationPoint;
}

export interface CreateFreeRegistration {
  date: Date;
  weight: number;
  comment?: string;
  reasonId?: string;
  avoidable: string;
}

export interface CurrentRegistration {
  // id of the registration point
  id: string;
  name: string;
  image?: string | null;
  amount: number;
  status?: RegistrationStatus;
  selected?: boolean;
  unit: string;
  date: string;
  registrationId?: string;
  comment?: string;
  reasonId?: string;
  offline?: boolean;
}

export interface FunFact {
  nameKey: string;
  image: string;
  amount: number;
}

export enum RegistrationActionTypes {
  SET_WEIGHT = 'esmiley/registration/SET_WEIGHT',
  SET_REASON = 'esmiley/registration/SET_REASON',
  SET_DATE = 'esmiley/registration/SET_DATE',
  SET_SCALE_STATUS = 'esmiley/registration/SET_SCALE_STATUS',
  REGISTER_SUCCESS = 'esmiley/registration/REGISTER_SUCCESS',
  REGISTER_FAILURE = 'esmiley/registration/REGISTER_FAILURE',
  REGISTER_REQUEST = 'esmiley/registration/REGISTER_REQUEST',
  UPDATE = 'esmiley/registration/UPDATE',
  UPDATE_STEP = 'esmiley/registration/UPDATE_STEP',
  UPDATE_PAGINATION = 'esmiley/registration/UPDATE_PAGINATION',
  UPDATE_REGISTRATION_POINTS = 'esmiley/registration/UPDATE_REGISTRATION_POINTS',
  UPDATE_STEPPER = 'esmiley/registration/UPDATE_STEPPER',
  SET_COMMENT = 'esmiley/registration/SET_COMMENT',
  UPDATE_CURRENT_REGISTRATIONS = 'esmiley/registration/UPDATE_CURRENT_REGISTRATIONS',
  MULTIPLE_REGISTER_SUCCESS = 'esmiley/registration/MULTIPLE_REGISTER_SUCCESS',
  FINISH_MULTIPLE_REGISTRATION = 'esmiley/registration/FINISH_MULTIPLE_REGISTRATION',
  SET_AVOIDABLE = 'esmiley/registration/SET_AVOIDABLE',
  CHANGE_FIELD_VALIDATION = 'esmiley/registration/CHANGE_FIELD_VALIDATION',
  SET_OPTIONAL_REGISTRATION = 'esmiley/registration/SET_OPTIONAL_REGISTRATION'
}

type RegisterSuccessAction = {
  type: typeof RegistrationActionTypes.REGISTER_SUCCESS;
  payload: Registration;
};

type UpdateStepAction = {
  type: typeof RegistrationActionTypes.UPDATE_STEP;
  payload: StepShape;
};

type UpdatePaginationAction = {
  type: typeof RegistrationActionTypes.UPDATE_PAGINATION;
  payload: number;
};

type SetDateAction = {
  type: typeof RegistrationActionTypes.SET_DATE;
  payload: Date;
};

type SetWeightAction = {
  type: typeof RegistrationActionTypes.SET_WEIGHT;
  payload: number;
};

type SetReasonAction = {
  type: typeof RegistrationActionTypes.SET_REASON;
  payload: string;
};

type SetScaleStatusAction = {
  type: typeof RegistrationActionTypes.SET_SCALE_STATUS;
  payload: ScaleStatus;
};

type RegisterRequestAction = {
  type: typeof RegistrationActionTypes.REGISTER_REQUEST;
};

type RegisterRequestFailureAction = {
  type: typeof RegistrationActionTypes.REGISTER_FAILURE;
};

type UpdateRegistrationPointsAction = {
  type: typeof RegistrationActionTypes.UPDATE_REGISTRATION_POINTS;
  payload: {
    registrationPoints: RegistrationPoint[];
    selectionParentPath: string[];
  };
};

type UpdateStepperAction = {
  type: typeof RegistrationActionTypes.UPDATE_STEPPER;
  payload: string[];
};

type SetCommentAction = {
  type: typeof RegistrationActionTypes.SET_COMMENT;
  payload: string;
};

type SetOptionalRegistrationAction = {
  type: typeof RegistrationActionTypes.SET_OPTIONAL_REGISTRATION;
  payload: RegistrationPoint;
};

type UpdateCurrentRegistrationsAction = {
  type: typeof RegistrationActionTypes.UPDATE_CURRENT_REGISTRATIONS;
  payload: CurrentRegistration[];
};

type MultipleRegisterSuccessAction = {
  type: typeof RegistrationActionTypes.MULTIPLE_REGISTER_SUCCESS;
  payload: Registration;
};

type SetAvoidableAction = {
  type: typeof RegistrationActionTypes.SET_AVOIDABLE;
  payload: RegistrationAvoidable;
};

type ChangeFieldValidationAction = {
  type: typeof RegistrationActionTypes.CHANGE_FIELD_VALIDATION;
  payload: RegistrationFieldValidation[];
};

type FinishMultipleRegistrationAction = {
  type: typeof RegistrationActionTypes.FINISH_MULTIPLE_REGISTRATION;
};

export type RegistrationActions =
  | RegisterSuccessAction
  | UpdateStepAction
  | UpdatePaginationAction
  | SetDateAction
  | SetReasonAction
  | SetScaleStatusAction
  | RegisterRequestAction
  | RegisterRequestFailureAction
  | UpdateRegistrationPointsAction
  | UpdateStepperAction
  | SetWeightAction
  | SetCommentAction
  | UpdateCurrentRegistrationsAction
  | MultipleRegisterSuccessAction
  | SetAvoidableAction
  | ChangeFieldValidationAction
  | FinishMultipleRegistrationAction
  | SetOptionalRegistrationAction;
