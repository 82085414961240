import HelpText from 'components/HelpText';
import { FormControlLabel, makeStyles, Switch } from '@material-ui/core';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { SettingsState } from 'redux/ducks/settings';

interface AllowSkipLastRegistrationPointProps {
  settings: Pick<
    SettingsState,
    'allowSkipLastRegistrationPoint' | 'lockedByAdmin' | 'overrideChildSettings'
  >;
  onUpdateSettings: (data) => void;
}

type OwnProps = AllowSkipLastRegistrationPointProps;

const useStyles = makeStyles(() => {
  return {
    allowSkipStep: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    submit: {
      maxHeight: '36px'
    }
  };
});

const AllowSkipLastRegistrationPoint: React.FunctionComponent<OwnProps> = ({
  settings,
  onUpdateSettings
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { allowSkipLastRegistrationPoint, lockedByAdmin, overrideChildSettings } = settings;
  const { allowSkipLastRegistrationPoint: hasAllowedSkipLastStep } = lockedByAdmin || {};

  const handleUpdateSettings = (isEnabled?: boolean) => {
    let updateSettings: Partial<SettingsState> = {
      allowSkipLastRegistrationPoint: isEnabled,
      allowMultipleRegistrations: false
    };

    if (!isEnabled && overrideChildSettings?.allowSkipLastRegistrationPoint?.enabled) {
      updateSettings = {
        ...updateSettings,
        overrideChildSettings: {
          ...overrideChildSettings,
          allowSkipLastRegistrationPoint: {
            enabled: false,
            overridePrevious: true
          }
        }
      };
    }

    onUpdateSettings(updateSettings);
  };

  return (
    <div className={`settingToggle ${classes.allowSkipStep}`}>
      <div>
        <FormControlLabel
          control={
            <Switch
              color={'primary'}
              disabled={hasAllowedSkipLastStep}
              checked={allowSkipLastRegistrationPoint}
              onChange={(e, isInputChecked) => {
                handleUpdateSettings(isInputChecked);
              }}
            />
          }
          label={intl.formatMessage({ id: 'settings.allowSkipLastRegistrationPoint' })}
        />
        <HelpText
          helpText={intl.formatMessage({ id: 'help.settings.allowSkipLastRegistrationPoint' })}
        >
          &nbsp;
        </HelpText>
      </div>
    </div>
  );
};

export default AllowSkipLastRegistrationPoint;
