import LoadingPlaceholder from 'components/LoadingPlaceholder';
import FailedPlaceholder from 'components/FailedPlaceholder';
import Container from 'components/Container';
import { GridList, GridListTile } from '../ResponsiveGridList';
import DataPlaceholder from 'components/DataPlaceholder';
import SettingsIcon from '@material-ui/icons/Settings';
import * as registrationDispatch from 'redux/ducks/registration';
import * as React from 'react';
import { connect } from 'react-redux';
import { WithRouterProps, withRouter } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  RegistrationPoint,
  showMultipleRegistrationCard
} from 'redux/ducks/data/registrationPoints';
import { Fade } from '@material-ui/core';
import GuestRegistrationTile from 'pages/Registration/RegistrationPointSelection/GuestRegistrationCardTile';
import MultiRegistrationTile from 'pages/Registration/RegistrationPointSelection/MultiRegistrationCardTile';
import ReasonOnlyTile from 'pages/Registration/RegistrationPointSelection/ReasonOnlyTile';
import ProductPlaceholder from 'static/img/product_placeholder.png';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { getSelectedChildPoints, RegistrationActions } from 'redux/ducks/registration';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface OwnProps {
  initRegistrationPoints: () => void;
}

type RegistrationPointSelectionProps = StateProps & DispatchProps & WithRouterProps & OwnProps;

const RegistrationPointSelection: React.FC<RegistrationPointSelectionProps> = (props) => {
  const intl = useIntl();
  const {
    isScaleClient,
    initializing,
    failed,
    initRegistrationPoints,
    registrationPoints,
    selectRegistrationPoint,
    selectGuestRegistration,
    allowRegistrationsOnAnyPoint,
    showAddGuestRegistration,
    router,
    displayMultipleRegistrationCard,
    handleSelectMultipleRegistration,
    reasonOnlyEnabled,
    reasonOnlyId
  } = props;

  const handleSelectGuestRegistration = () => {
    selectGuestRegistration();
  };

  const handleTileClick = (
    event: React.MouseEvent<HTMLElement>,
    registrationPoint: RegistrationPoint
  ) => {
    // check if user click tile or button
    // if button, go directly to registration-page
    // else display children registrationPoints

    (event.target as HTMLElement).tagName == 'IMG' || !allowRegistrationsOnAnyPoint
      ? selectRegistrationPoint(registrationPoint, false)
      : selectRegistrationPoint(registrationPoint, true);
  };

  const handleReasonOnlyRegistration = () => {
    const reasonOnlyPoint = {
      name: intl.formatMessage({ id: 'addNewReasonRegistration' }),
      id: reasonOnlyId
    } as RegistrationPoint;
    if (reasonOnlyId !== undefined) {
      selectRegistrationPoint(reasonOnlyPoint, true);
    }
  };

  React.useEffect(() => {
    if (reasonOnlyEnabled && !showAddGuestRegistration && !initializing) {
      handleReasonOnlyRegistration();
    }
  }, [reasonOnlyEnabled, showAddGuestRegistration, initializing]);

  if (initializing) {
    return <LoadingPlaceholder />;
  } else if (failed) {
    return <FailedPlaceholder retryHandler={initRegistrationPoints} />;
  }

  if (registrationPoints.length === 0) {
    const placeholderDescription = intl
      .formatMessage({ id: 'registration.no_data.description' })
      .split('#');
    const title = intl.formatMessage({ id: 'registration.product_selection.no_data.title' });

    if (isScaleClient) {
      return <DataPlaceholder title={title} />;
    }

    return (
      <DataPlaceholder
        buttonHandler={() => {
          router.push('/settings');
        }}
        buttonIcon={<SettingsIcon />}
        title={title}
        description={
          <span>
            {placeholderDescription[0]}
            <SettingsIcon />
            {placeholderDescription[1]}
          </span>
        }
      />
    );
  }

  return (
    <Container title={<FormattedMessage id='registrationPoints' />}>
      <GridList style={{ width: '100%' }}>
        {showAddGuestRegistration ? (
          <Fade key={registrationPoints.length} in timeout={750}>
            <GuestRegistrationTile
              onSelectGuestRegistration={handleSelectGuestRegistration}
              isSelected
            />
          </Fade>
        ) : null}

        {displayMultipleRegistrationCard && (
          <Fade key='mr' in timeout={750}>
            <MultiRegistrationTile onClick={handleSelectMultipleRegistration} />
          </Fade>
        )}

        {!reasonOnlyEnabled &&
          registrationPoints.map((item: RegistrationPoint) => (
            <Fade in key={item.id} timeout={750}>
              <GridListTile
                name={item.name}
                image={item.image || ProductPlaceholder}
                value={item}
                onClick={handleTileClick}
                isSelected
                allowRegistrationsOnAnyPoint={allowRegistrationsOnAnyPoint}
              />
            </Fade>
          ))}
        {reasonOnlyEnabled && (
          <Fade key={'reason_only'} in timeout={750}>
            <ReasonOnlyTile onSelectReasonOnlyRegistration={handleReasonOnlyRegistration} />
          </Fade>
        )}
      </GridList>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  isScaleClient: state.user.client === 'scale',
  initial: state.data.registrationPoints.initial,
  initializing: state.data.registrationPoints.initializing,
  failed: state.data.registrationPoints.failed,
  registrationPoints: getSelectedChildPoints(state),
  reasonOnlyEnabled: state.settings.allowReasonRegistrationsOnly,
  allowRegistrationsOnAnyPoint: state.settings.allowRegistrationsOnAnyPoint,
  reasonOnlyId: state.settings?.reasonOnlyId,
  showAddGuestRegistration:
    state.settings.enableGuestRegistrationFlow &&
    state.registration.step === 0 &&
    state.registration.selectionParentPath.length === 0,
  displayMultipleRegistrationCard: showMultipleRegistrationCard(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, RegistrationActions>) => ({
  selectRegistrationPoint: (registrationPoint: RegistrationPoint, registerDirectly?: boolean) =>
    dispatch(registrationDispatch.selectRegistrationPoint(registrationPoint, registerDirectly)),
  selectGuestRegistration: () => dispatch(registrationDispatch.selectGuestRegistration()),
  handleSelectMultipleRegistration: () =>
    dispatch(registrationDispatch.selectMultipleRegistrations()),
  selectReasonRegistration: () => dispatch(registrationDispatch.selectReasonRegistration())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegistrationPointSelection));
