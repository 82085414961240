import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { RegistrationPoint } from 'redux/ducks/data/registrationPoints';

interface OptionalSelectProps {
  onSelect: (point: RegistrationPoint) => void;
  points: RegistrationPoint[];
  selected?: RegistrationPoint;
}

interface OptionalSelectOptionProps {
  point: RegistrationPoint;
  selected?: boolean;
  onClick: (point: RegistrationPoint) => void;
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '16px'
  },
  formControl: {
    width: '100%',
    marginLeft: '10px'
  },
  radio: {
    '&$checked': {
      color: '#018b87'
    }
  },
  formControlLabelRoot: {
    width: '100%',
    border: '2px solid #c4e4e2',
    borderRadius: '1px',
    padding: '10px 10px 10px 10px',
    marginBottom: '15px'
  },
  formControlLabelRootChecked: {
    width: '100%',
    padding: '10px 10px 10px 10px',
    backgroundColor: '#e0f2f1',
    borderRadius: '1px',
    border: '2px solid #e0f2f1',
    marginBottom: '15px'
  },
  formControlLabel: {
    color: theme.palette.text.primary,
    fontSize: '16px'
  },
  formControlLabelBold: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 'bold'
  }
}));

const OptionalRegister: React.FunctionComponent<OptionalSelectProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const { onSelect, points, selected = {} as RegistrationPoint } = props;

  const handleSelect = (point: RegistrationPoint) => {
    onSelect(point);
  };

  return (
    <div>
      <Typography component='h1' variant='h3' className={classes.title}>
        {intl.formatMessage({ id: 'settings.allowSkipLastRegistrationPoint.selectOption' })}
      </Typography>
      <FormControl className={classes.formControl}>
        <RadioGroup>
          {points.map((point, index) => (
            <OptionalRegisterOption
              onClick={handleSelect}
              selected={point.id === selected.id}
              point={point}
              key={index}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default OptionalRegister;

const OptionalRegisterOption: React.FunctionComponent<OptionalSelectOptionProps> = ({
  onClick,
  selected,
  point
}) => {
  const classes = useStyles();

  const isActiveRoot = selected
    ? classes.formControlLabelRootChecked
    : classes.formControlLabelRoot;
  const isActiveLabel = selected ? classes.formControlLabelBold : classes.formControlLabel;

  const handleChange = () => {
    onClick(point);
  };

  return (
    <FormControlLabel
      classes={{
        root: isActiveRoot,
        label: isActiveLabel
      }}
      value={point.id}
      name={point.name}
      checked={selected}
      control={
        <Radio
          disableRipple
          onChange={handleChange}
          color='primary'
          classes={{ root: classes.radio }}
        />
      }
      label={point.name}
    />
  );
};
