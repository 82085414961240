import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import { RootState } from 'redux/rootReducer';
import { RegistrationPoint } from 'redux/ducks/data/registrationPoints/types';

const getRegistrationPointsTree = (state: RootState) => {
  return state.data.registrationPoints.tree || [];
};

// create a custom "selector creator" that uses lodash.isEqual instead of the default equality check "==="
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

// depth first order required for material table search to work
function depthFirstTreeFlattener(guardFn: (tree: RegistrationPoint) => boolean = null) {
  return (treeList: RegistrationPoint[]): RegistrationPoint[] => {
    return treeList.reduce<RegistrationPoint[]>(function traverse(
      nodeList,
      tree
    ): RegistrationPoint[] {
      if (guardFn && guardFn(tree)) {
        return nodeList;
      }
      return nodeList.concat(tree, (tree.children || []).reduce(traverse, []));
    },
    []);
  };
}

const flattenAll = depthFirstTreeFlattener();
const flattenActive = depthFirstTreeFlattener((tree) => !tree.active);
const flattenNonDeleted = depthFirstTreeFlattener((tree) => !!tree.deletedAt);

export const getAllRegistrationPointsDepthFirst = createDeepEqualSelector(
  [getRegistrationPointsTree],
  flattenAll
);

export const getActiveRegistrationPointsDepthFirst = createDeepEqualSelector(
  [getRegistrationPointsTree],
  flattenActive
);

export const getNonDeletedRegistrationPointsDepthFirst = createDeepEqualSelector(
  [getRegistrationPointsTree],
  flattenNonDeleted
);

export const hasOnlyLeafPoints = (state: RootState) => {
  const { selectionParentPath } = state.registration;
  const { allNodes } = state.data.registrationPoints;

  const currentParent = selectionParentPath.slice(-1)[0];

  if (!currentParent) {
    return false;
  }

  const children = allNodes.filter(
    (node) => node.parentId === currentParent && node.active && !node.deletedAt
  );
  const leaves = children.filter(
    (child) =>
      !allNodes.some((node) => node.parentId === child.id && node.active && !node.deletedAt)
  );
  return leaves.length > 1 && leaves.length === children.length;
};

export const showMultipleRegistrationCard = (state: RootState) => {
  return state.settings.allowMultipleRegistrations && hasOnlyLeafPoints(state);
};
