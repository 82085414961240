import { Grid, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import * as registrationDispatch from 'redux/ducks/registration';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { RegistrationActions } from 'redux/ducks/registration';
import KeyPad from 'components/Keypad';
import { useState } from 'react';
import { RegistrationPoint } from 'redux/ducks/data/registrationPoints';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core/styles';
import moment, { Moment } from 'moment/moment';
import isRegistrationLockedForDate from 'pages/Registration/utils/isRegistrationLocked';
import InlineDatePicker from 'components/InlineDatePicker';
import SubmitButton from 'pages/Registration/Register/AmountInsertion/SubmitButton';
import { useIntl } from 'react-intl';

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
interface OwnProps {
  currentRegistrationPoint: RegistrationPoint;
  date: Date;
}

interface ComponentProps extends OwnProps, StoreProps, DispatchProps {}

const WeightPerItemHandler: React.FC<ComponentProps> = (props) => {
  const {
    setWeight,
    setDate,
    enableLockedRegistrations,
    currentRegistrationPoint,
    date,
    multipleRegistrationsDone
  } = props;
  const classes = styles(props);
  const intl = useIntl();
  const theme = useTheme();
  const previousDateRef = React.useRef<HTMLButtonElement>(null);
  const nextDateRef = React.useRef<HTMLButtonElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isDesktop = useMediaQuery('(min-width:450px)');
  const isLimitedHeight = useMediaQuery('(max-height: 500px)');
  const isLimitedHeightAndNoMobile = !isMobile && isLimitedHeight;

  const [items, setItems] = useState(0);

  const handleChange = (value: number) => {
    setItems(value);
    setWeight(value * (currentRegistrationPoint?.amount / 1000));
  };

  const handleDateChange = (newDate: Moment) => {
    const date = new Date(newDate.toDate());
    date.setHours(0, 0, 0, 0);
    setDate(date);
  };

  return (
    <Grid item xs={12} className='weight-container'>
      <div
        className={classNames(classes.root, {
          [classes.mobileForm]: isMobile || !isDesktop,
          [classes.floatRightForm]: isLimitedHeightAndNoMobile
        })}
      >
        {(isMobile || isLimitedHeightAndNoMobile) && (
          <InlineDatePicker
            className={classes.calendar}
            value={moment(date)}
            onChange={handleDateChange}
            refs={{ leftArrowButton: previousDateRef, rightArrowButton: nextDateRef }}
            shouldDisableDate={(date) =>
              isRegistrationLockedForDate(new Date(date.toDate()), enableLockedRegistrations)
            }
          />
        )}
        <KeyPad disabled={false} value={items} onChange={handleChange} />
        {(isMobile || isLimitedHeightAndNoMobile) && (
          <SubmitButton disabled={!items || items === 0} fullWidth>
            {multipleRegistrationsDone
              ? intl.formatMessage({ id: 'registration.btnFinished' })
              : intl.formatMessage({ id: 'registration.btn' })}
          </SubmitButton>
        )}
      </div>
    </Grid>
  );
};

const fixedHeightQuery = '@media screen and (min-height: 1024px)';

const styles = makeStyles<Theme, ComponentProps>((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    backgroundColor: '#333333',
    padding: '2vh',
    height: '100%',
    '& > * + * ': {
      marginTop: '2vh'
    },
    [fixedHeightQuery]: {
      padding: '16px',
      '& > * + * ': {
        marginTop: '16px'
      }
    }
  },
  button: {
    width: '100%',
    borderRadius: '5px!important',
    marginBottom: 'auto'
  },
  calendar: {
    backgroundColor: '#595959',
    '& .MuiButton-root': {
      color: theme.palette.common.white,
      fill: theme.palette.common.white
    }
  },
  mobileForm: {
    transition: 'all 0.3s ease-in-out',
    zIndex: 600,
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: 70,
      left: 0,
      width: '100%',
      height: 'auto'
    }
  },
  floatRightForm: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    height: 'auto',
    overflow: 'auto',
    zIndex: 9000 /* has to be on top of topnav */
  }
}));

const mapStateToProps = (state: RootState) => ({
  weight: state.registration.weight,
  massUnit: state.settings.unit,
  multipleRegistrationsDone:
    state.registration.allRegistrationPointsAreHandled &&
    state.registration.currentRegistrations.length > 0,
  enableLockedRegistrations: state.settings.enableLockedRegistrations
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, RegistrationActions>) => ({
  setWeight: (weight: number) => {
    dispatch(registrationDispatch.setWeight(weight));
  },
  setDate: (date: Date) => {
    dispatch(registrationDispatch.setDate(date));
  }
});

export default connect<StoreProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(WeightPerItemHandler);
