import * as React from 'react';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { connect } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { Spinner } from 'components/LoadingPlaceholder';

type StateProps = ReturnType<typeof mapStateToProps>;

export type SubmitButtonProps = ButtonProps & StateProps;

const SubmitButton = (props: SubmitButtonProps) => {
  const { children, loading, disabled, ...rest } = props;

  return (
    <Button
      className={'registrationButton'}
      type={'submit'}
      color='primary'
      variant='contained'
      disabled={disabled || loading}
      startIcon={loading ? <Spinner size='small' /> : undefined}
      {...rest}
    >
      {children}
    </Button>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.registration.loading
});

export default connect<StateProps, unknown, ButtonProps>(mapStateToProps, null)(SubmitButton);
