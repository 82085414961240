import { DataTransfer } from 'frontend-core';
import { AxiosResponse } from 'axios';
import { fetchRegistrations, Registration } from 'redux/ducks/data/registrations';
import moment from 'moment';
import { unformatMass } from 'components/FormattedMass';
import { API_DATE_FORMAT } from 'utils/datetime';
import {
  DataRegistrationPointsActions,
  RegistrationPoint
} from 'redux/ducks/data/registrationPoints';
import {
  CreateFreeRegistration,
  CurrentRegistration,
  RegistrationActions,
  RegistrationActionTypes,
  RegistrationAvoidable,
  RegistrationFieldValidation,
  RegistrationState,
  RegistrationStatus,
  ScaleStatus,
  StepShape
} from 'redux/ducks/registration/types';
import { ErrorActions, displayError } from 'redux/ducks/error';
import { ThunkResult } from 'redux/types';
import {
  currentRegistrationDataFormat,
  getMultipleRegistration,
  updateCurrentRegistrationArray
} from 'redux/ducks/registration/selectors';
import { SettingsState } from 'redux/ducks/settings';

export * from './types';
export * from './selectors';

const transfer = new DataTransfer();

const currentDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear());
  date.setHours(0, 0, 0, 0);
  return date;
};

const UpdateDateIntervalInMins = 30;

const initialRegistrationState: Omit<RegistrationState, 'date' | 'updatedAt' | 'scaleStatus'> = {
  weight: undefined,
  step: 0,
  loading: false,
  pageNumber: 0,
  currentNodes: [],
  reasonId: undefined,
  currentRegistrations: [],
  // currentNodes.length === currentRegistrations with status.length
  allRegistrationPointsAreHandled: false,
  avoidable: undefined,
  funFact: null,
  invalidFields: [],
  selectionParentPath: [],
  optionalSelected: undefined
};

export const initialState: RegistrationState = {
  date: currentDate(),
  updatedAt: new Date(),
  scaleStatus: {
    isConnected: false
  },
  ...initialRegistrationState
};

export default function reducer(
  state: RegistrationState = initialState,
  action: RegistrationActions
): RegistrationState {
  switch (action.type) {
    case RegistrationActionTypes.SET_WEIGHT:
      return { ...state, weight: action.payload };
    case RegistrationActionTypes.SET_REASON:
      return { ...state, reasonId: action.payload };
    case RegistrationActionTypes.SET_DATE:
      return { ...state, date: action.payload, updatedAt: new Date() };
    case RegistrationActionTypes.SET_COMMENT:
      return { ...state, comment: action.payload };
    case RegistrationActionTypes.SET_OPTIONAL_REGISTRATION:
      return { ...state, optionalSelected: action.payload };
    case RegistrationActionTypes.REGISTER_REQUEST:
      return { ...state, loading: true };
    case RegistrationActionTypes.REGISTER_SUCCESS: {
      const { payload } = action;
      const registration = currentRegistrationDataFormat(
        payload.registrationPoint,
        payload,
        'registered'
      );
      const currentRegistrations = updateCurrentRegistrationArray([], registration);
      return {
        ...state,
        ...initialRegistrationState,
        date: currentDate(),
        updatedAt: new Date(),
        funFact: action.payload.funFact,
        currentRegistrations
      };
    }
    case RegistrationActionTypes.REGISTER_FAILURE: {
      return { ...state, loading: false };
    }
    case RegistrationActionTypes.UPDATE_STEP:
      return { ...state, step: action.payload };
    case RegistrationActionTypes.UPDATE_REGISTRATION_POINTS:
      return {
        ...state,
        currentNodes: action.payload.registrationPoints,
        currentRegistrations: [],
        pageNumber: 0,
        selectionParentPath: action.payload.selectionParentPath
      };
    case RegistrationActionTypes.SET_SCALE_STATUS:
      return { ...state, scaleStatus: action.payload };
    case RegistrationActionTypes.UPDATE_PAGINATION:
      return { ...state, pageNumber: action.payload };
    case RegistrationActionTypes.UPDATE_STEPPER:
      return { ...state, pageNumber: 0, step: 0, selectionParentPath: action.payload };
    case RegistrationActionTypes.UPDATE_CURRENT_REGISTRATIONS:
      return {
        ...state,
        currentRegistrations: action.payload,
        allRegistrationPointsAreHandled: allRegistrationPointsAreProcessed(
          state.currentNodes,
          action.payload
        )
      };
    case RegistrationActionTypes.MULTIPLE_REGISTER_SUCCESS: {
      const { payload } = action;
      const registration = currentRegistrationDataFormat(
        payload.registrationPoint,
        payload,
        'registered'
      );
      const currentRegistrations = updateCurrentRegistrationArray(
        state.currentRegistrations,
        registration
      );
      return {
        ...state,
        comment: '',
        loading: false,
        updatedAt: new Date(),
        weight: undefined,
        invalidFields: [],
        currentRegistrations,
        allRegistrationPointsAreHandled: allRegistrationPointsAreProcessed(
          state.currentNodes,
          currentRegistrations
        )
      };
    }
    case RegistrationActionTypes.FINISH_MULTIPLE_REGISTRATION: {
      const { currentRegistrations } = state;
      return {
        ...state,
        ...initialRegistrationState,
        currentRegistrations,
        date: currentDate(),
        updatedAt: new Date()
      };
    }
    case RegistrationActionTypes.SET_AVOIDABLE:
      return { ...state, avoidable: action.payload };
    case RegistrationActionTypes.CHANGE_FIELD_VALIDATION: {
      return { ...state, invalidFields: action.payload };
    }
    default:
      return state;
  }
}

export const updateStep = (
  step: StepShape
): ThunkResult<RegistrationActions, RegistrationActions | DataRegistrationPointsActions> => {
  return (dispatch, getState) => {
    const { updatedAt } = getState().registration;
    const currentDate = new Date();
    // next step is registration weight page,
    // check set date and update it to current date,
    // if set date was updated more than given interval.
    // this is only necessary, because we allow users
    // to set data and go back to change registration point
    if (step === 1) {
      if (moment(currentDate.getTime()).diff(updatedAt, 'minutes') > UpdateDateIntervalInMins) {
        dispatch(setDate(currentDate));
      }
    }

    return dispatch({
      type: RegistrationActionTypes.UPDATE_STEP,
      payload: step
    });
  };
};

export const updatePagination = (pageNumber: number): RegistrationActions => ({
  type: RegistrationActionTypes.UPDATE_PAGINATION,
  payload: pageNumber
});

export const setDate = (date: Date): RegistrationActions => ({
  type: RegistrationActionTypes.SET_DATE,
  payload: date
});

export const setComment = (comment: string): RegistrationActions => ({
  type: RegistrationActionTypes.SET_COMMENT,
  payload: comment
});

export const setScaleStatus = (status: ScaleStatus): RegistrationActions => ({
  type: RegistrationActionTypes.SET_SCALE_STATUS,
  payload: status
});

export function register(): ThunkResult<
  Promise<RegistrationActions | ErrorActions>,
  RegistrationActions | DataRegistrationPointsActions | ErrorActions
> {
  return async (dispatch, getState) => {
    const {
      scaleStatus: { isConnected: isScaleConnected },
      date,
      weight,
      comment,
      reasonId,
      currentNodes,
      allRegistrationPointsAreHandled,
      avoidable,
      optionalSelected
    } = getState().registration;

    // when pressing finish registration button (multiregister)
    if (currentNodes.length > 1 && allRegistrationPointsAreHandled) {
      return dispatch({
        type: RegistrationActionTypes.FINISH_MULTIPLE_REGISTRATION
      });
    }

    const { enableAutoTare } = getState().settings;
    const invalidFields = validateRegistrationFields(getState().registration, getState().settings);

    if (invalidFields.length > 0) {
      return dispatch({
        type: RegistrationActionTypes.CHANGE_FIELD_VALIDATION,
        payload: invalidFields
      });
    }

    // todo: refactor state to use registration array to avoid this hack,
    // confusing and pointless to have registrations array and registration data flatten in the state now
    const registrations = getMultipleRegistration(getState());
    const amount = weight ? parseInt((unformatMass(weight) as number).toFixed(0)) : undefined;

    // todo refactor out selectedIndex and use currentNodes only to avoid ternary/registrations
    const selectedRegistrationPoint =
      registrations.length > 1
        ? registrations.find((value) => value.selected === true)
        : optionalSelected
        ? optionalSelected
        : currentRegistrationDataFormat(currentNodes[0]);

    // this should never happen:
    if (!selectedRegistrationPoint) {
      dispatch({
        type: RegistrationActionTypes.REGISTER_FAILURE
      });

      return dispatch(displayError(new Error('missing registration point')));
    }

    dispatch({
      type: RegistrationActionTypes.REGISTER_REQUEST
    });

    const data = {
      scale: isScaleConnected,
      amount,
      comment,
      date: moment(date).format(API_DATE_FORMAT),
      registrationPointId: selectedRegistrationPoint.id,
      unit: 'g',
      reasonId: reasonId,
      avoidable
    };

    try {
      const response = (await transfer.post(
        '/foodwaste/registrations',
        data
      )) as AxiosResponse<Registration>;
      // todo why is this done?
      await dispatch(
        fetchRegistrations({
          createdAt: {
            from: moment().subtract(1, 'year').startOf('day').toISOString(),
            to: moment().endOf('day').toISOString()
          }
        })
      );

      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage && enableAutoTare) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ command: 'tare' }));
      }

      if (currentNodes.length > 1) {
        return dispatch({
          type: RegistrationActionTypes.MULTIPLE_REGISTER_SUCCESS,
          payload: response.data
        });
      }

      return dispatch({
        type: RegistrationActionTypes.REGISTER_SUCCESS,
        payload: response.data
      });
    } catch (error: unknown) {
      dispatch({
        type: RegistrationActionTypes.REGISTER_FAILURE
      });

      return dispatch(displayError(error as Error));
    }
  };
}

const validateRegistrationFields = (
  state: RegistrationState,
  settings: SettingsState
): RegistrationFieldValidation[] => {
  const { weight, reasonId } = state;
  const invalidFields = [] as RegistrationFieldValidation[];

  if (!weight) {
    invalidFields.push({ field: 'weight', reason: 'missing' });
  }

  if (weight) {
    const parsedWeight = parseInt((unformatMass(weight) as number).toFixed(0));
    // minimum required weight is 1g
    if (!parsedWeight || parsedWeight < 1) {
      invalidFields.push({ field: 'weight', reason: 'invalid' });
    }
  }

  if (settings.enableRegistrationReason && !reasonId) {
    invalidFields.push({ field: 'reason', reason: 'missing' });
  }

  return invalidFields;
};

export function selectRegistrationPoint(
  registrationPoint: RegistrationPoint,
  registerDirectly?: boolean
): ThunkResult<
  RegistrationActions | DataRegistrationPointsActions,
  RegistrationActions | DataRegistrationPointsActions
> {
  return (dispatch, getState) => {
    const { allNodes } = getState().data.registrationPoints;
    const { selectionParentPath } = getState().registration;
    const { allowSkipLastRegistrationPoint } = getState().settings;

    const children = allNodes.filter(
      (node) => node.parentId === registrationPoint.id && node.active && !node.deletedAt
    );

    const isLastStep = children.every((point: RegistrationPoint) =>
      allNodes.filter((node) => node.active).every((n) => n.parentId !== point.id)
    );
    const showPointsAsOptionalList = allowSkipLastRegistrationPoint && isLastStep;

    if (children.length > 0 && !registerDirectly && !showPointsAsOptionalList) {
      return dispatch({
        type: RegistrationActionTypes.UPDATE_REGISTRATION_POINTS,
        payload: {
          selectionParentPath: [...selectionParentPath, registrationPoint.id],
          registrationPoints: [registrationPoint]
        }
      });
    }

    dispatch({
      type: RegistrationActionTypes.UPDATE_REGISTRATION_POINTS,
      payload: {
        selectionParentPath: [...selectionParentPath, registrationPoint.id],
        registrationPoints: [registrationPoint]
      }
    });

    return dispatch(updateStep(1));
  };
}

export function selectOptionalRegistrationPoint(
  registrationPoint: RegistrationPoint
): RegistrationActions {
  return {
    type: RegistrationActionTypes.SET_OPTIONAL_REGISTRATION,
    payload: registrationPoint
  };
}

export function selectGuestRegistration(): RegistrationActions {
  return { type: RegistrationActionTypes.UPDATE_STEP, payload: 2 };
}

export function selectReasonRegistration(): RegistrationActions {
  return { type: RegistrationActionTypes.UPDATE_STEP, payload: 1 };
}

export function updateStepper(
  index: number
): ThunkResult<RegistrationActions, RegistrationActions | DataRegistrationPointsActions> {
  return (dispatch, getState) => {
    const { selectionParentPath } = getState().registration;

    return dispatch({
      type: RegistrationActionTypes.UPDATE_STEPPER,
      payload: selectionParentPath.slice(0, index)
    });
  };
}

export function resetStepper(): ThunkResult<
  RegistrationActions,
  RegistrationActions | DataRegistrationPointsActions
> {
  return (dispatch) => {
    return dispatch({
      type: RegistrationActionTypes.UPDATE_STEPPER,
      payload: []
    });
  };
}

export function setWeight(weight: number): RegistrationActions {
  return {
    type: RegistrationActionTypes.SET_WEIGHT,
    payload: weight
  };
}

export function setReason(reasonId: string): RegistrationActions {
  return {
    type: RegistrationActionTypes.SET_REASON,
    payload: reasonId
  };
}

export function resetCurrentRegistrations(): RegistrationActions {
  return {
    type: RegistrationActionTypes.UPDATE_CURRENT_REGISTRATIONS,
    payload: []
  };
}

export function selectMultipleRegistrations(): ThunkResult<
  RegistrationActions | DataRegistrationPointsActions,
  RegistrationActions | DataRegistrationPointsActions
> {
  return (dispatch, getState) => {
    const { selectionParentPath } = getState().registration;
    const { allNodes } = getState().data.registrationPoints;
    const parentId = selectionParentPath.slice(-1)[0];

    const registrationPoints = allNodes.filter(
      (node) => node.parentId === parentId && node.active && !node.deletedAt
    );

    dispatch({
      type: RegistrationActionTypes.UPDATE_REGISTRATION_POINTS,
      payload: {
        registrationPoints,
        selectionParentPath
      }
    });

    return dispatch(updateStep(1));
  };
}

export function updateMultipleRegistrations(
  statusType: RegistrationStatus
): ThunkResult<RegistrationActions, RegistrationActions> {
  return (dispatch, getState) => {
    const { currentRegistrations, date } = getState().registration;
    const selectedCurrentRegistration = getMultipleRegistration(getState()).find(
      (value) => value.selected === true
    );

    const updatedRegistrations = updateCurrentRegistrationArray(currentRegistrations, {
      ...selectedCurrentRegistration,
      status: statusType,
      date: moment(date).format(API_DATE_FORMAT)
    });

    return dispatch({
      type: RegistrationActionTypes.UPDATE_CURRENT_REGISTRATIONS,
      payload: updatedRegistrations
    });
  };
}

// only in multiregister
function allRegistrationPointsAreProcessed(
  currentNodes: RegistrationPoint[],
  currentRegistrations: CurrentRegistration[]
): boolean {
  const registeredPoints = currentRegistrations.filter((registration) => !!registration.status);
  return currentNodes.length > 0 && registeredPoints.length === currentNodes.length;
}

export function editRegistration(
  registration: CurrentRegistration
): ThunkResult<Promise<RegistrationActions | ErrorActions>, RegistrationActions | ErrorActions> {
  return async (dispatch, getState) => {
    const { date, currentRegistrations } = getState().registration;
    const { amount, id, comment, reasonId, status, registrationId } = registration;
    const { features } = getState().features;
    const hasFullFeature = features.some((feature) => feature.name === 'registration.full');

    if (status !== 'skipped') {
      void (await dispatch(deleteRegistrationMulti(registrationId)));
    }

    const data = {
      scale: false,
      amount,
      comment,
      date: moment(date).format(API_DATE_FORMAT),
      registrationPointId: id,
      unit: 'g',
      reasonId: reasonId
    };

    try {
      const response = (await transfer.post(
        '/foodwaste/registrations',
        data
      )) as AxiosResponse<Registration>;

      void (await dispatch(
        fetchRegistrations({
          createdAt: {
            from: moment()
              .subtract(1, hasFullFeature ? 'year' : 'week')
              .startOf('day')
              .toISOString(),
            to: moment().endOf('day').toISOString()
          }
        })
      ));

      const updatedRegistrations = updateCurrentRegistrationArray(currentRegistrations, {
        ...registration,
        status: 'registered',
        date: response.data.date,
        registrationId: response.data.id,
        reasonId: response.data.reasonId,
        comment: response.data.comment
      });

      return dispatch({
        type: RegistrationActionTypes.UPDATE_CURRENT_REGISTRATIONS,
        payload: updatedRegistrations
      });
    } catch (error: unknown) {
      dispatch({
        type: RegistrationActionTypes.REGISTER_FAILURE
      });

      return dispatch(displayError(error as Error));
    }
  };
}

export function skipRegistrationAction(
  registration: CurrentRegistration
): ThunkResult<Promise<RegistrationActions>, RegistrationActions> {
  return async (dispatch, getState) => {
    const { currentRegistrations } = getState().registration;

    void (await dispatch(deleteRegistrationMulti(registration.registrationId)));

    const updatedRegistrations = updateCurrentRegistrationArray(currentRegistrations, {
      ...registration,
      status: 'skipped',
      registrationId: undefined
    });

    void (await dispatch(
      fetchRegistrations({
        createdAt: {
          from: moment().subtract(1, 'year').startOf('day').toISOString(),
          to: moment().endOf('day').toISOString()
        }
      })
    ));

    return dispatch({
      type: RegistrationActionTypes.UPDATE_CURRENT_REGISTRATIONS,
      payload: updatedRegistrations
    });
  };
}

function deleteRegistrationMulti(
  registrationId: string
): ThunkResult<Promise<Registration | ErrorActions>, ErrorActions> {
  return async (dispatch) => {
    try {
      const result = (await transfer.delete(
        `/foodwaste/registrations/${registrationId}`
      )) as AxiosResponse<Registration>;
      return result.data;
    } catch (error: unknown) {
      return dispatch(displayError(error as Error));
    }
  };
}

export function setAvoidable(avoidable: RegistrationAvoidable): RegistrationActions {
  return {
    type: RegistrationActionTypes.SET_AVOIDABLE,
    payload: avoidable
  };
}

// todo merge this with register()
// currently starter plan uses separate validation in the view
export function registerFreePlan(
  registration: CreateFreeRegistration
): ThunkResult<
  Promise<RegistrationActions | ErrorActions>,
  RegistrationActions | DataRegistrationPointsActions | ErrorActions
> {
  return async (dispatch, getState) => {
    const { date, weight, comment, reasonId, avoidable } = registration;
    const allNodes = getState().data.registrationPoints.allNodes;
    const roots = allNodes.filter((node) => !node.parentId);
    // makes no sense to store weight in kgs, should fix to use g later
    const amount = weight ? parseInt((unformatMass(weight) as number).toFixed(0)) : undefined;

    // workaround for PR
    // will be replaced by the real bootstrapped registrationPointId
    const selectedRegistrationPoint = roots.filter((tree) => tree.active && !tree.deletedAt)[0];

    dispatch({
      type: RegistrationActionTypes.REGISTER_REQUEST
    });

    const data = {
      scale: false,
      amount,
      comment,
      date: moment(date).format(API_DATE_FORMAT),
      registrationPointId: selectedRegistrationPoint.id,
      unit: 'g',
      reasonId: reasonId,
      avoidable
    };

    try {
      const response = (await transfer.post(
        '/foodwaste/registrations',
        data
      )) as AxiosResponse<Registration>;
      // why is this done?
      await dispatch(
        fetchRegistrations({
          createdAt: {
            from: moment().subtract(1, 'week').startOf('day').toISOString(),
            to: moment().endOf('day').toISOString()
          }
        })
      );

      return dispatch({
        type: RegistrationActionTypes.REGISTER_SUCCESS,
        payload: response.data
      });
    } catch (error: unknown) {
      dispatch({
        type: RegistrationActionTypes.REGISTER_FAILURE
      });

      return dispatch(displayError(error as Error));
    }
  };
}
