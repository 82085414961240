import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from 'redux/rootReducer';
import { getSettings } from 'redux/ducks/settings';
import { Button, Card, CardActions, CardContent, Chip } from '@material-ui/core';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { update as updateSettings } from 'redux/ducks/settings';
import CardHeader from 'pages/Settings/components/settings/goals/CardHeader';
import LockIcon from '@material-ui/icons/Lock';
import AllowSkipLastRegistrationPoint from 'pages/Settings/components/settings/registrations/AllowSkipLastRegistrationPoint';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type GoalsProps = StateProps & DispatchProps;

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column wrap'
  },
  content: {
    flexGrow: 1
  },
  cardActions: {
    '& > :last-child': { marginLeft: 'auto' }
  }
}));

const RegistrationCard: React.FunctionComponent<GoalsProps> = (props) => {
  const classes = useStyles(props);
  const intl = useIntl();
  const {
    updateSettings,
    overrideChildSettings,
    hasSubscribedAccounts,
    lockedByAdmin,
    allowSkipLastRegistrationPoint
  } = props;
  const { allowSkipLastRegistrationPoint: hasAllowedSkipLastStep } = lockedByAdmin || {};

  const handleOverrideSubAccounts = () => {
    updateSettings({
      overrideChildSettings: {
        ...overrideChildSettings,
        allowSkipLastRegistrationPoint: {
          enabled: allowSkipLastRegistrationPoint,
          overridePrevious: true
        }
      }
    });
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <CardHeader title={intl.formatMessage({ id: 'settings.targets.registrations' })} />
        <AllowSkipLastRegistrationPoint
          settings={{ allowSkipLastRegistrationPoint, lockedByAdmin, overrideChildSettings }}
          onUpdateSettings={updateSettings}
        />
      </CardContent>
      <CardActions
        className={classNames({
          [classes.cardActions]:
            lockedByAdmin.allowSkipLastRegistrationPoint || hasSubscribedAccounts
        })}
      >
        {lockedByAdmin.allowSkipLastRegistrationPoint && (
          <Chip
            variant='outlined'
            label={intl.formatMessage({ id: 'settings.targets.locked' })}
            icon={<LockIcon fontSize='small' />}
          />
        )}
        {hasSubscribedAccounts && (
          <Button
            disabled={hasAllowedSkipLastStep}
            color='primary'
            variant='contained'
            onClick={handleOverrideSubAccounts}
          >
            {intl.formatMessage({ id: 'settings.targets.applyToSubaccounts' })}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

const mapStateToProps = (state: RootState) => ({
  overrideChildSettings: state.settings.overrideChildSettings || {},
  hasSubscribedAccounts: state.subAccounts.subscribed.length !== 0,
  lockedByAdmin: getSettings(state).lockedByAdmin,
  allowSkipLastRegistrationPoint: state.settings.allowSkipLastRegistrationPoint
});

const mapDispatchToProps = {
  updateSettings
};
export default connect<StateProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationCard);
