import { useIntl } from 'react-intl';
import './index.scss';
import Container from 'components/Container';
import * as registrationDispatch from 'redux/ducks/registration';
import Overview from './Overview';
import AmountInsertion from './AmountInsertion';
import { Grid, Fade } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { onSubmitForm } from 'utils/helpers';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { getMultipleRegistration, RegistrationActions } from 'redux/ducks/registration';
import { NotificationActions } from 'redux/ducks/notification';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RegisterProps = StateProps & DispatchProps;

const Register: React.FunctionComponent<RegisterProps> = (props) => {
  const intl = useIntl();
  const { register } = props;

  const handleRegister = () => {
    void register();
  };

  return (
    <Fade in={true} timeout={750}>
      <Container title={intl.formatMessage({ id: 'registration.btn' })} className='register-step'>
        <form onSubmit={onSubmitForm(handleRegister)}>
          <Grid container spacing={4} className='register-grid-container' justify={'space-around'}>
            <Grid container item xs={12} sm={6} md={6} justify={'center'} alignItems={'flex-start'}>
              <Overview />
            </Grid>
            <Grid container item xs={12} sm={6} md={5} justify={'center'} alignItems={'flex-start'}>
              <AmountInsertion />
            </Grid>
          </Grid>
        </form>
      </Container>
    </Fade>
  );
};

const mapStateToProps = (state: RootState) => ({
  step: state.registration.step,
  reason: state.registration.reasonId,
  weight: state.registration.weight,
  date: state.registration.date,
  // todo make a selector to get current nodes, multi or not
  registrationPoint: state.registration.currentNodes[0],
  registrations: getMultipleRegistration(state),
  reasonsEnabled: state.settings.enableRegistrationReason
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, void, RegistrationActions | NotificationActions>
) => ({
  register: () => dispatch(registrationDispatch.register())
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
